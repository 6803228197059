.wip-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: #f3f4f6;
    padding: 20px;
    text-align: center;
  }
  
  .wip-message {
    max-width: 600px;
  }
  
  .wip-title {
    font-family: "The Seasons", sans-serif;
    font-size: 3rem;
    color: #21204c;
    margin-bottom: 20px;
  }
  
  .wip-subtitle {
    font-family: "Antipasto", sans-serif;
    font-size: 1.8rem;
    font-weight: 200;
    color: #21204c;
  }
  