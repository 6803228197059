/* import the fonts from the assets folder and make them the main font */
/* Antipasto font */
@font-face {
  font-family: 'Antipasto';
  src: url('../src/assets/fonts/Antipasto-Pro-Regular-trial.ttf');
}


/* The Seasons font */

@font-face {
  font-family: 'The Seasons';
  src: url('../src/assets/fonts/Fontspring-DEMO-theseasons-reg.otf');
}


.number-font {
  font-family: serif;
}


body {
  margin: 0;
  padding: 0;
  font-family: 'Antipasto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: "../src/assets/images/poppy_pattern_transparent.png";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


